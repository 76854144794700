var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-flex tw-flex-row tw-items-center",staticStyle:{"min-width":"0"}},[(_vm.tab.reorderable)?_c('v-icon',{staticClass:"handle",staticStyle:{"cursor":"move","margin-right":"2px"},attrs:{"x-small":"","disabled":_vm.editing,"color":_vm.iconColor},domProps:{"textContent":_vm._s('$custom-drag-handle')}}):_vm._e(),(_vm.tab.icon)?_c('v-tooltip',{attrs:{"bottom":"","z-index":"100","open-delay":"400","disabled":!_vm.tab.iconTooltip},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({class:_vm.small ? 'tw-mr-1' : 'tw-mr-2',attrs:{"size":_vm.tab.iconSize || (_vm.small ? 12 : 16),"color":_vm.tab.iconColor || (!_vm.inactive && _vm.selected ? _vm.color : 'grey lighten-3')},domProps:{"textContent":_vm._s(_vm.tab.icon)}},'v-icon',attrs,false),on))]}}],null,false,3873059298)},[_c('span',[_vm._v(_vm._s(_vm.tab.iconTooltip))])]):(_vm.tab.image)?_c('img',{staticClass:"tw-mr-1",attrs:{"src":_vm.tab.image,"width":"12","height":"12"}}):_vm._e(),_c('span',{key:_vm.tab.editable && _vm.editing,ref:"nameInput",class:{
      'tw-text-sm': !_vm.small,
      'tw-text-xs': _vm.small,
      'tw-truncate tw-overflow-hidden': !_vm.editing,
      'tw-font-medium': _vm.selected,
      'grey--text text--lighten-3': !_vm.selected,
      'tw-truncate tw-overflow-hidden': !_vm.editing
    },staticStyle:{"outline":"none","min-width":"1px"},attrs:{"id":`text-${_vm.tab.id}`,"role":"textbox","spellcheck":"false","title":_vm.tab.text,"contenteditable":_vm.tab.editable && _vm.editing},on:{"input":function($event){return _vm.$emit('input', $event)},"blur":function($event){return _vm.$emit('submit', $event)},"keypress":function($event){return _vm.$emit('keypress', $event)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.$emit('keypress', $event)}}},[_vm._t("text",function(){return [_vm._v(" "+_vm._s(_vm.tab.text)+" ")]},{"text":_vm.tab.text})],2),(_vm.tab.editable && _vm.editing && !_vm.editingValue && _vm.placeholder)?_c('span',{staticClass:"tw-text-sm grey--text text--lighten-3"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e(),(_vm.tab.caption !== undefined)?_c('span',{staticClass:"tw-text-xs grey--text tw-ml-1",class:{
      'text--lighten-3': _vm.tab.caption,
      'text--lighten-5': !_vm.tab.caption
    }},[_vm._v(" "+_vm._s(_vm.tab.caption)+" ")]):_vm._e(),(_vm.tab.badge)?_c('v-badge',{staticClass:"transparent-background tw-mb-1 tw-ml-1",attrs:{"color":"accent","inline":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('div',{staticClass:"tw-text-xs tw-font-bold accent--text"},[_vm._v(" "+_vm._s(_vm.tab.badge)+" ")])]},proxy:true}],null,false,2184271360)}):_vm._e(),(_vm.tab.editable && _vm.tab.actions)?_c('span',{staticClass:"tw-flex tw-flex-row tw-items-center tw--mr-1",staticStyle:{"margin-left":"2px"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();},"mousedown":function($event){$event.stopPropagation();$event.preventDefault();},"touchstart":function($event){$event.stopPropagation();$event.preventDefault();}}},[(_vm.tab.editable)?_c('v-btn',{attrs:{"ripple":false,"icon":"","x-small":"","color":"grey lighten-3","disabled":_vm.editing},on:{"click":function($event){return _vm.$emit('edit', $event)}}},[_c('v-icon',{attrs:{"size":_vm.small ? 10 : 12},domProps:{"textContent":_vm._s('$fas-pencil-alt')}})],1):_vm._e(),(_vm.tab.actions)?_vm._t("actions",null,{"tab":_vm.tab,"disabled":_vm.editing}):_vm._e()],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }