
import Vue from 'vue'
import Component from 'vue-class-component'
import { Location } from 'vue-router'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'
import { RouteModule } from '@/modules/route/store'

@Component({
  name: 'AppbarLogoDense'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  routeModule = getModule(RouteModule, this.$store)

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get to (): Location {
    if (this.currentLandscapeId && this.currentVersionId) {
      return {
        name: 'landscape',
        params: {
          landscapeId: this.currentLandscapeId,
          versionId: this.currentVersionId
        },
        query: {
          domain: this.$query.domain
        }
      }
    } else if (this.currentOrganizationId) {
      return {
        name: 'landscapes',
        params: {
          organizationId: this.currentOrganizationId
        },
        query: {
          domain: this.$query.domain
        }
      }
    } else {
      return {
        name: 'organizations'
      }
    }
  }
}
