
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'

import { ITab } from './tabs.vue'

@Component({
  name: 'TabContent'
})
export default class extends Vue {
  @Ref() readonly nameInput!: HTMLInputElement

  @Prop() readonly tab!: ITab

  @Prop({ type: Boolean }) readonly inactive?: boolean
  @Prop({ default: false, type: Boolean }) readonly small?: boolean
  @Prop() readonly color?: string
  @Prop() readonly placeholder?: string

  @Prop() readonly selected!: boolean
  @Prop() readonly editing!: boolean
  @Prop() readonly editingValue!: string
  @Prop() readonly reordering!: boolean
  @Prop() readonly hover!: boolean

  get iconColor () {
    if (this.inactive) {
      return 'grey lighten-3'
    } else if (this.editing) {
      return 'grey lighten-5'
    } else if (this.selected || this.reordering) {
      return 'accent'
    } else if (this.hover) {
      return 'grey lighten-3'
    } else {
      return 'grey lighten-5'
    }
  }
}
