
/* eslint-disable import/no-webpack-loader-syntax */
import Vue from 'vue'
import Component from 'vue-class-component'

import Dialog from '@/components/dialog.vue'
import * as analytics from '@/helpers/analytics'

@Component({
  components: {
    Dialog
  },
  name: 'LicensesDialog'
})
export default class extends Vue {
  containerScroll = 0

  licenses = ''

  async opened () {
    analytics.licensesDialog.track(this)

    const { default: licenses } = await import(/* webpackChunkName: "licenses" */ 'raw-loader!../../../../licenses')
    this.licenses = licenses
  }
}
