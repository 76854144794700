
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'

@Component({
  name: 'Menu'
})
export default class extends Vue {
  @Prop({ default: null }) readonly name!: string | null

  @Prop({ default: 'background' }) readonly color!: string
  @Prop() readonly minWidth!: string | number | undefined
  @Prop() readonly maxWidth!: string | number | undefined
  @Prop() readonly minHeight!: number | undefined
  @Prop() readonly activator!: (() => HTMLElement | { $el: HTMLElement } | undefined) | undefined
  @Prop({ default: '1' }) readonly urlDefaultValue!: string
  @Prop({ default: false, type: Boolean }) readonly offsetOverflow!: boolean
  @Prop() readonly disabled: boolean | undefined
  @Prop({ type: Boolean }) readonly absolute: boolean | undefined
  @Prop() readonly positionX: number | undefined
  @Prop() readonly positionY: number | undefined
  @Prop({ default: false, type: Boolean }) readonly centerX!: boolean
  @Prop({ default: false }) readonly loading!: boolean
  @Prop() readonly attach!: any
  @Prop() readonly rounded!: boolean | string
  @Prop({ default: 100 }) readonly zIndex?: number

  @Prop({ default: false, type: Boolean }) readonly closeOnContentClick!: boolean
  @Prop() readonly closeOnClick: boolean | undefined
  @Prop() readonly closeDelay: number | undefined

  @Prop() readonly openOnClick: boolean | undefined
  @Prop() readonly openOnHover: boolean | undefined
  @Prop() readonly openDelay: number | undefined

  @Prop() readonly border: string | undefined
  @Prop() readonly shadow: string | undefined
  @Prop({ default: false, type: Boolean }) readonly pulse!: boolean

  @Prop({ type: Boolean }) readonly left: boolean | undefined
  @Prop({ type: Boolean }) readonly bottom: boolean | undefined
  @Prop({ type: Boolean }) readonly right: boolean | undefined
  @Prop({ type: Boolean }) readonly top: boolean | undefined

  @Prop({ type: Boolean }) readonly offsetX: boolean | undefined
  @Prop({ type: Boolean }) readonly offsetY: boolean | undefined

  @Prop() readonly nudgeBottom!: string | number
  @Prop() readonly nudgeLeft!: string | number
  @Prop() readonly nudgeRight!: string | number
  @Prop() readonly nudgeTop!: string | number

  @Ref() readonly menu!: any
  @Ref() readonly content!: { $el: HTMLElement }

  visible = false
  initialShowTimer?: number
  width = 0
  activatorWidth = 0

  get query () {
    return this.name ? this.$queryValue(this.name) : null
  }

  get nudgeLeftValue () {
    if (this.centerX && this.width && this.activatorWidth) {
      return ((this.width - this.activatorWidth) / 2) - (parseInt(`${this.nudgeLeft}`) || 0)
    } else if (this.nudgeLeft) {
      return this.nudgeLeft
    }
  }

  created () {
    this.width = parseInt(`${this.minWidth || this.maxWidth}`) || 0
  }

  mounted () {
    if (this.name && this.query) {
      this.initialShowTimer = window.setInterval(() => {
        this.visible = true
        clearInterval(this.initialShowTimer)

        if (this.activator?.()) {
          this.$nextTick(() => {
            this.calculateWidths()
          })
        }
      }, 100)
    }
  }

  destroyed () {
    if (this.initialShowTimer) {
      clearInterval(this.initialShowTimer)
    }
    this.$emit('close')
    this.visible = false
    this.$emit('closed')

    if (this.name && this.query && !module.hot) {
      this.$replaceQuery({
        [this.name]: undefined
      })
    }
  }

  @Watch('visible')
  onVisibleChanged (visible: boolean, prevVisible: boolean) {
    if (visible !== prevVisible) {
      if (visible) {
        if (this.name && !this.query) {
          this.$replaceQuery({
            [this.name]: this.urlDefaultValue
          })
        }
        this.$nextTick(() => {
          this.calculateWidths()
          this.$emit('open')
        })
        setTimeout(() => {
          if (this.visible) {
            this.calculateWidths()
            this.$emit('opened')
          }
        }, 200)
      } else {
        if (this.name && this.query) {
          this.$replaceQuery({
            [this.name]: undefined
          })
        }
        this.$nextTick(() => {
          this.$emit('close')
        })
        setTimeout(() => {
          if (!this.visible) {
            this.$emit('closed')
          }
        }, 200)
      }
    }
  }

  @Watch('query')
  onQueryChanged (query: any) {
    if (this.name) {
      this.visible = !!query
    }
  }

  @Watch('disabled')
  async onDisabledChanged (disabled: boolean, prevDisabled: boolean) {
    if (!disabled && prevDisabled && this.visible) {
      this.visible = false
      await this.$nextTick()
      this.visible = true
    }
  }

  updateDimensions () {
    this.menu?.updateDimensions()
  }

  calculateWidths () {
    this.width = this.content?.$el.clientWidth || this.width
    const activator = this.activator?.() || this.menu.activatorElement
    const activatorElement = activator && '$el' in activator ? activator.$el : activator
    this.activatorWidth = activatorElement ? activatorElement.offsetWidth : 0
  }

  open () {
    this.visible = true
  }

  close () {
    this.visible = false
  }
}
