
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  name: 'ContextMenuItem'
})
export default class ContextMenuItem extends Vue {
  @Prop({ type: Boolean }) disabled?: boolean
  @Prop() disabledMessage?: string
  @Prop({ type: Boolean }) readonly active?: boolean
  @Prop({ type: Boolean }) readonly selected?: boolean
}
