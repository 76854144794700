
import Vue from 'vue'
import Component from 'vue-class-component'

import Dialog from '@/components/dialog.vue'
import * as analytics from '@/helpers/analytics'
import changelog from '@/helpers/changelog'

const whatsNewVersionsSeenKey = 'whatsNewVersionsSeen'

@Component({
  components: {
    Dialog
  },
  name: 'WhatsNewDialog'
})
export default class extends Vue {
  versionsSeen: string[] = JSON.parse(localStorage.getItem(whatsNewVersionsSeenKey) || '[]')

  containerScroll = 0

  get sections () {
    return [
      {
        icon: '$custom-plus-circle',
        iconColor: 'accent',
        id: 'added',
        name: 'Added'
      },
      {
        icon: '$fas-bug',
        iconColor: 'error',
        id: 'fixed',
        name: 'Squashed bugs'
      },
      {
        icon: '$custom-pencil',
        iconColor: 'info',
        id: 'changed',
        name: 'Changed'
      }
    ]
  }

  get versions () {
    return changelog
  }

  get lastSeenVersion () {
    return this.versions.find(o => this.versionsSeen.includes(o.name))
  }

  get lastSeenMajorVersion () {
    return this.lastSeenVersion?.name.split('.')[0]
  }

  get newVersions () {
    return this.versions.filter(o => !this.versionsSeen.includes(o.name))
  }

  get newMajorVersions () {
    return this.newVersions.map(o => o.name.split('.')[0])
  }

  get latestNewVersion () {
    return this.newVersions[0]
  }

  mounted () {
    setTimeout(() => {
      if (this.lastSeenVersion) {
        if (this.newMajorVersions.some(o => o !== this.lastSeenMajorVersion)) {
          this.$replaceQuery({
            whats_new_dialog: '1'
          })
        }
      } else {
        this.versionsSeen = this.versions.map(o => o.name)
        localStorage.setItem(whatsNewVersionsSeenKey, JSON.stringify(this.versionsSeen))
      }
    }, 3000)
  }

  opened () {
    analytics.whatsNewDialog.track(this)
  }

  closed () {
    this.versionsSeen = this.versions.map(o => o.name)
    localStorage.setItem(whatsNewVersionsSeenKey, JSON.stringify(this.versionsSeen))
  }
}
