
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'

import cumulativeOffset from '@/helpers/cumulative-offset'

@Component({
  name: 'ContextMenu'
})
export default class ContextMenu extends Vue {
  @Prop() readonly menuProps?: any
  @Prop() readonly value?: any
  @Prop({ default: 260 }) readonly width!: number | string

  @Ref() readonly menuRef!: any

  openLeft = false

  open () {
    this.menuRef.isActive = true
  }

  close () {
    this.menuRef.isActive = false
  }

  updateDimensions () {
    this.menuRef.updateDimensions()
  }

  mounted () {
    this.calculateOffsetOverflow()
  }

  input (value: boolean) {
    this.calculateOffsetOverflow()

    this.$emit('input', value)
    this.$emit(value ? 'open' : 'close')
  }

  calculateOffsetOverflow () {
    const activatorElement = this.menuRef.activatorElement as HTMLElement | null
    if (activatorElement) {
      const { left } = cumulativeOffset(activatorElement)
      this.openLeft = left + activatorElement.clientWidth + Number(this.width) > window.innerWidth - 20
    }
  }
}
