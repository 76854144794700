
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { Location } from 'vue-router'
import Draggable from 'vuedraggable'

import { caretRangeFromPoint } from '@/helpers/caret-position'
import cumulativeOffset from '@/helpers/cumulative-offset'

import TabContent from './tab-content.vue'

const urlsEqual = (a: string, b: string) => {
  const urlA = new URL(a, 'https://app.icepanel.io')
  const urlB = new URL(b, 'https://app.icepanel.io')
  urlA.searchParams.sort()
  urlB.searchParams.sort()
  return urlA.toString() === urlB.toString()
}

export interface ITab {
  actions?: boolean
  badge?: number
  caption?: string | number
  editable?: boolean
  icon?: string
  iconColor?: string
  iconSize?: number
  iconTooltip?: string
  id: string
  image?: string
  reorderable?: boolean
  text: string
  to?: Location
}

@Component({
  components: {
    Draggable,
    TabContent
  },
  name: 'Tabs'
})
export default class extends Vue {
  @Prop() readonly tabs!: ITab[]
  @Prop({ default: 'white' }) readonly color!: string
  @Prop() readonly height?: number | string
  @Prop({ type: Boolean }) readonly exactPath?: boolean
  @Prop({ type: Boolean }) readonly inactive?: boolean
  @Prop({ type: Boolean }) readonly disabled?: boolean
  @Prop({ type: Boolean }) readonly replace?: boolean
  @Prop({ default: false, type: Boolean }) readonly small?: boolean
  @Prop({ default: true, type: Boolean }) readonly ripple?: boolean
  @Prop({ default: false, type: Boolean }) readonly autoFocus?: boolean
  @Prop({ default: false, type: Boolean }) readonly showArrows?: boolean
  @Prop() readonly placeholder?: string

  @Ref() readonly tabsRef: any
  @Ref() readonly tabContent!: TabContent[]

  editingIndex = -1
  editingValue = ''

  reorderIndex = -1
  reorderScrollDirection: 'left' | 'right' | undefined
  reorderMouseInterval?: number

  get selectedIndex () {
    return this.tabsResolved.findIndex(o => {
      if (this.exactPath) {
        return !!o.path && urlsEqual(o.path, this.$route.path)
      } else {
        return !!o.to && urlsEqual(o.to, this.$route.fullPath)
      }
    })
  }

  get tabsMapped (): ITab[] {
    return this.tabs.map(o => ({
      ...o,
      reorderable: o.reorderable ? this.tabs.length > 1 : undefined
    }))
  }

  get tabsResolved () {
    return this.tabsMapped.map(o => {
      const resolve = o.to ? this.$router.resolve(o.to) : undefined
      return {
        ...o,
        path: resolve?.route.path,
        to: resolve?.href
      }
    })
  }

  @Watch('tabs')
  onTabsChanged (tabs: ITab[], prevTabs: ITab[]) {
    this.resize()

    const prevTabIds = prevTabs.map(o => o.id)
    const newTabIndex = tabs.findIndex(o => !o.text && !prevTabIds.includes(o.id))
    if (this.autoFocus && newTabIndex > -1) {
      this.edit(newTabIndex, true)
    }
  }

  @Watch('inactive')
  onInactiveChanged () {
    this.resize()
  }

  @Watch('editingIndex')
  onEditingIndexChanged (editingIndex: number, prevEditingIndex: number) {
    if (editingIndex > -1 && prevEditingIndex <= -1) {
      this.$emit('edit', editingIndex)
    } else if (editingIndex <= -1 && prevEditingIndex > -1) {
      this.$emit('edited', editingIndex)
    }
  }

  mounted () {
    if (this.autoFocus && this.tabs.length && !this.tabs[0].text) {
      this.edit(0, true)
    }
  }

  destroyed () {
    this.reorderEnd()
  }

  resize () {
    this.tabsRef?.onResize()
  }

  click (to: string) {
    if (to !== this.$route.fullPath) {
      if (this.replace) {
        this.$router.replace(to)
      } else {
        this.$router.push(to)
      }
    }
  }

  async edit (index: number, selectAll: boolean, e?: any) {
    if (this.editingIndex !== index) {
      const tab = this.tabs[index]
      this.editingIndex = index
      this.editingValue = tab.text

      await this.$nextTick()

      this.resize()

      const el = this.tabContent.find(o => o.nameInput?.id === `text-${tab.id}`)?.nameInput
      if (el) {
        if (selectAll || !e) {
          const sel = window.getSelection()
          sel?.removeAllRanges()
          const range = document.createRange()
          range.selectNodeContents(el)
          sel?.addRange(range)
          el.focus()
        } else if (e) {
          const range = caretRangeFromPoint(e.clientX, e.clientY)
          if (range) {
            const sel = window.getSelection()
            sel?.removeAllRanges()
            sel?.addRange(range)
            el.focus()
          }
        }
      }
    }
  }

  input (e: any) {
    this.resize()
    this.editingValue = e.target.innerText
  }

  submit (index: number, e: any) {
    const tab = this.tabs[index]
    const newText = e.target.innerText

    if (tab) {
      if (tab.text && !newText) {
        e.target.innerText = tab.text
      } else if (this.placeholder && !newText) {
        this.$emit('update', tab.id, this.placeholder, tab.text)
        e.target.innerText = this.placeholder
      } else if (newText && tab.text !== newText) {
        this.$emit('update', tab.id, newText, tab.text)
        e.target.innerText = newText
      }
    }

    this.editingValue = ''
    this.editingIndex = -1
    this.resize()
  }

  keypress (index: number, e: any) {
    const tab = this.tabs[index]

    if (tab) {
      if (e.key === 'Enter') {
        e.preventDefault()
        const target: HTMLElement = e.target
        target.blur()
      } else if (e.key === 'Escape') {
        e.preventDefault()
        const target: HTMLElement = e.target
        if (tab.text) {
          target.innerText = tab.text
        }
        target.blur()
      }
    }
  }

  reorderStart (index: number) {
    this.reorderIndex = index

    const tabBar = this.tabsRef.$children[0]

    clearInterval(this.reorderMouseInterval)
    this.reorderMouseInterval = window.setInterval(() => {
      if (this.reorderScrollDirection === 'left') {
        tabBar.scrollOffset = Math.max(0, tabBar.scrollOffset - 20)
      } else if (this.reorderScrollDirection === 'right') {
        const tabBarElement = tabBar.$el as HTMLElement
        const slideGroupWrapper = tabBarElement.querySelector('.v-slide-group__wrapper')
        const slideGroupContent = slideGroupWrapper?.querySelector('.v-slide-group__content')
        const maxScrollOffset = slideGroupContent && slideGroupWrapper ? slideGroupContent.clientWidth - slideGroupWrapper.clientWidth : 0
        tabBar.scrollOffset = Math.min(maxScrollOffset, tabBar.scrollOffset + 20)
      }
    }, 100)
  }

  reorderMove (e: any) {
    const tabsOffset = cumulativeOffset(this.tabsRef.$el)
    if (e.relatedRect.left <= tabsOffset.left + 50) {
      this.reorderScrollDirection = 'left'
    } else if (e.relatedRect.right >= tabsOffset.left - 50 + this.tabsRef.$el.clientWidth) {
      this.reorderScrollDirection = 'right'
    } else {
      this.reorderScrollDirection = undefined
    }
  }

  reorderEnd () {
    clearInterval(this.reorderMouseInterval)
    this.reorderIndex = -1
  }

  updateIndex ({ moved }: { moved?: { newIndex: number, oldIndex: number } }) {
    if (moved) {
      this.$emit('reorder', moved.oldIndex, moved.newIndex)
    }
  }
}
