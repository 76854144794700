
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  name: 'Appbar'
})
export default class extends Vue {
  get appbarLeftKey () {
    return this.appbarKey('appbar-left', 1)
  }

  get appbarRightKey () {
    return this.appbarKey('appbar-right')
  }

  appbarKey (name: string, offset = 0) {
    return Object.entries(this.$slots).slice(offset).filter(([k]) => k.startsWith(name)).filter(([, v]) => v && v[0]).map(([, v]) => v?.[0].tag).join('_')
  }
}
