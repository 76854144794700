
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  name: 'ScreenWarningInterstitial'
})
export default class extends Vue {
  viewWidth = 0
  shownWarning = false

  get showWarning (): boolean {
    // Using any-hover to detect touch screen devices,
    // making sure touch-enabled laptops are not mistakenly matched.
    // https://www.w3.org/TR/mediaqueries-4/#descdef-media-any-pointer
    return (this.viewWidth < this.minScreenWidth || window.matchMedia('(any-hover: none)').matches) &&
            !this.shownWarning
  }

  get minScreenWidth (): number {
    return 720
  }

  mounted () {
    this.resize()
  }

  created () {
    if (localStorage.getItem('shownScreenWarning')) {
      this.shownWarning = true
    }
  }

  resize () {
    this.viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  }

  closeWarning () {
    localStorage.setItem('shownScreenWarning', 'true')
    this.shownWarning = true
  }
}
