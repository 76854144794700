
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Component({
  name: 'Dialog'
})
export default class extends Vue {
  @Prop({ default: null }) readonly name!: string | null
  @Prop({ default: 'primary' }) readonly color!: string
  @Prop({ default: '1' }) readonly urlDefaultValue!: string
  @Prop({ default: 700 }) readonly width!: number
  @Prop({ default: true }) readonly closeIcon!: boolean
  @Prop({ default: false }) readonly loading!: boolean
  @Prop({ default: false, type: Boolean }) readonly persistent: boolean | undefined

  visible = false
  loaded = false

  get query () {
    return this.name ? this.$query[this.name] : null
  }

  mounted () {
    if (this.name && this.query) {
      this.visible = true
    }
  }

  destroyed () {
    if (this.visible) {
      this.$emit('close')
      this.visible = false
      this.$emit('closed')
    }

    this.loaded = false

    if (this.name && this.query && !module.hot) {
      this.$replaceQuery({
        [this.name]: undefined
      })
    }
  }

  @Watch('visible')
  onVisibleChanged (visible: boolean, prevVisible: boolean) {
    if (visible !== prevVisible) {
      if (visible) {
        if (this.name && !this.query) {
          this.$replaceQuery({
            [this.name]: this.urlDefaultValue
          })
        }
        this.$nextTick(() => {
          this.$emit('open')
        })
        setTimeout(() => {
          if (this.visible && !this.loading && !this.loaded) {
            this.loaded = true
            this.$emit('opened')
          }
        }, 200)
      } else {
        if (this.name && this.query) {
          this.$replaceQuery({
            [this.name]: undefined
          })
        }
        this.$nextTick(() => {
          this.$emit('close')
        })
        setTimeout(() => {
          if (!this.visible && this.loaded) {
            this.loaded = false
            this.$emit('closed')
          }
        }, 200)
      }
    }
  }

  @Watch('loading')
  onLoadingChanged (loading: boolean) {
    if (!loading && this.visible && !this.loaded) {
      this.loaded = true
      this.$emit('opened')
    }
  }

  @Watch('query')
  onQueryChanged (query: any) {
    if (this.name) {
      this.visible = !!query
    }
  }

  open () {
    this.visible = true
  }

  close () {
    this.visible = false
  }
}
